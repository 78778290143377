export enum JobTypeKey {
   StudentJob = 'student-job',
   TemporaryJob = 'temporary-job',
   PermanentJob = 'permanent-job',
   FlexiJob = 'flexi-job',
}

export interface JobType {
   id: number;
   name: string;
   key: JobTypeKey;
}
